.panel {
  position: fixed;
  padding: 0.5rem;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.2);
  z-index: 10;
  color: white;
  text-shadow: 2px 2px 2px black;
}
