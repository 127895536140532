body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(rgb(104, 231, 250), rgb(201, 242, 224));
  background: url("/public/img/alpacas-bg.svg");
  background-position: bottom center;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Riffic";
  src: url("/public/fonts/riffic.bold.woff2") format("woff2");
}

:root {
  --font-theme: "Riffic";
  --text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-theme);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}
